import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { GoogleMap, Marker, Polygon, Polyline, Circle, MarkerClusterer, InfoWindow, useJsApiLoader } from '@react-google-maps/api';

const libraries = ['places', 'geometry'];

const WasteManagementMap = ({
  managementLocations = [],
  reportedLocations = [],
  neighbourhoods = [],
  painPoints = []
}) => {
  // Use the useJsApiLoader hook to load Google Maps API
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBaLMJgUY0VmmLjg-sZzyvhPGQjBcaA5dM",
    libraries,
  });

  const convertLocationType = (type) => {
    if (!type) return 'recycling_yard';

    return type.toLowerCase()
      .replace('reciklažna dvorišta', 'recycling_yard')
      .replace('bio otpad', 'bio')
      .replace('plastika', 'plastic')
      .replace('papir', 'paper')
      .replace('staklo', 'glass')
      .replace('tekstil', 'textile');
  };

  // Map view state
  const [activeView, setActiveView] = useState('reported'); // 'reported', 'neighbourhoods', 'painpoints'

  // Selected elements state
  const [selectedWaste, setSelectedWaste] = useState(null);
  const [nearestFacilities, setNearestFacilities] = useState(null);
  const [selectedNeighbourhood, setSelectedNeighbourhood] = useState(null);
  const [selectedPainPoint, setSelectedPainPoint] = useState(null);
  const [clickedPins, setClickedPins] = useState([]);
  // Filter states
  const [visibleLocations, setVisibleLocations] = useState({
    plastic: false,
    paper: false,
    glass: false,
    bio: false,
    textile: false,
    recycling_yard: false
  });


  const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState(null);
  const [previousMapState, setPreviousMapState] = useState(null);
  const [isUserInteracting, setIsUserInteracting] = useState(false);

  const [mapCenter, setMapCenter] = useState({ lat: 45.77920020180329, lng: 16.00427376400895 });

  const filteredLocations = useMemo(() => {
    return managementLocations.filter(location => {
      const locationType = convertLocationType(location.type);
      const isNearestOfType = selectedWaste &&
        nearestFacilities &&
        Object.values(nearestFacilities).some(
          data =>
            data?.facility.lat === location.lat &&
            data?.facility.lng === location.lng
        );

      return visibleLocations[locationType] || isNearestOfType;
    });
  }, [
    managementLocations,
    visibleLocations,
    selectedWaste?.lat,  // Use specific properties instead of entire object
    selectedWaste?.lng,
    nearestFacilities ? Object.keys(nearestFacilities).length : 0 // Stable dependency
  ]);

  // Map reference
  const mapRef = useRef(null);



  // Constants
  const FACILITY_TYPES = [
    'Plastika',
    'Papir',
    'Staklo',
    'Bio otpad',
    'Tekstil',
    'Reciklažna dvorišta'
  ];

  const CATEGORIES = ['Plastika', 'Papir', 'Staklo', 'Bio otpad', 'Tekstil', 'Reciklažna dvorišta'];

  const defaultCenter = { lat: 45.77920020180329, lng: 16.00427376400895 };
  const defaultZoom = 11;

  useEffect(() => {
    resetSelections();
    if (mapRef.current && isLoaded) {
      mapRef.current.setZoom(defaultZoom);
      mapRef.current.panTo(defaultCenter);
    }
  }, [activeView, isLoaded]);

  // Reset all selected items
  const resetSelections = () => {
    setSelectedWaste(null);
    setNearestFacilities(null);
    setSelectedNeighbourhood(null);
    setSelectedPainPoint(null);
  };

  // Calculate zoom level based on points
  const calculateZoomForPoints = (center, points) => {
    if (!mapRef.current || !isLoaded) return;

    if (!points || points.length === 0) {
      mapRef.current.setCenter(center);
      mapRef.current.setZoom(14);
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();

    // Include center point
    bounds.extend(new window.google.maps.LatLng(center.lat, center.lng));

    // Extend bounds to include all other points
    points.forEach((point) => {
      bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
    });

    // Add padding to simulate fitting to 80% of the width
    const map = mapRef.current;
    const mapDiv = map.getDiv();
    const width = mapDiv.offsetWidth;
    const height = mapDiv.offsetHeight;

    // Calculate padding to shrink the effective width to 80%
    const horizontalPadding = (width * 0.2) / 2; // 10% left, 10% right
    const verticalPadding = (height * 0.2) / 2;  // Optional: same for height

    const padding = {
      top: verticalPadding,
      bottom: verticalPadding,
      left: horizontalPadding,
      right: horizontalPadding,
    };

    // Fit with padding
    map.fitBounds(bounds, padding);
  };

  // Find nearest facilities for a waste location
  const findNearestFacilities = (wasteLoc) => {
    return FACILITY_TYPES.reduce((acc, type) => {
      const facilitiesOfType = managementLocations.filter(facility =>
        facility.type === type
      );

      if (facilitiesOfType.length === 0) return acc;

      const nearest = facilitiesOfType.reduce((nearest, facility) => {
        const distance = calculateDistance(
          wasteLoc.lat, wasteLoc.lng,
          facility.lat, facility.lng
        );
        return (!nearest || distance < nearest.distance)
          ? { facility, distance }
          : nearest;
      }, null);

      return {
        ...acc,
        [type]: nearest
      };
    }, {});
  };

  // Calculate distance between two coordinates
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  // Custom icons for facility types
  const getFacilityIcon = useCallback((type) => {
    if (!isLoaded || !window.google) return null;

    // Use simpler iconUrl approach to avoid SVG path issues
    const iconUrl = {
      'Plastika': require('../../assets/images/plastika4d.svg'),
      'Papir': require('../../assets/images/papir4d.svg'),
      'Staklo': require('../../assets/images/staklo4d.svg'),
      'Bio otpad': require('../../assets/images/bio4d.svg'),
      'Tekstil': require('../../assets/images/tekstil4d.svg'),
      'Reciklažna dvorišta': require('../../assets/images/recik4d.svg')
    };

    return {
      url: iconUrl[type] || 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
      scaledSize: new window.google.maps.Size(40, 40),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(21, 37)
    };
  }, [isLoaded]);

  // Get color for facility type
  const getFacilityColor = () => {
    return 'rgba(34, 139, 34, 0.62)'
    //return colorMap[type] || '#FF0000';
  };

  const handleMouseOver = (index) => {
    setHoveredMarkerIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredMarkerIndex(null);
  };

  const getReportedIcon = useCallback((location, index) => {
    if (!isLoaded) return null;

    // First check if this marker is being hovered
    if (hoveredMarkerIndex === index) {
      return {
        url: require('../../assets/images/prijava_hover.svg'),
        scaledSize: new window.google.maps.Size(44, 44),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(20, 39)
      };
    }

    // If not hovered, use normal logic
    const isClicked = hasBeenClicked(location);
    const svgUrl = isClicked ?
      require('../../assets/images/prijava_pogledana.svg') :
      require('../../assets/images/prijavaff7022.svg');

    return {
      url: svgUrl,
      scaledSize: new window.google.maps.Size(44, 44),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(20, 39)
    };
  }, [isLoaded, clickedPins, hoveredMarkerIndex]);



  const hasBeenClicked = (location) => {
    return clickedPins.some(pin => pin.lat === location.lat && pin.lng === location.lng);
  };

  // Handle click on reported waste location
  const handleReportedLocationClick = (location) => {
    if (selectedWaste && selectedWaste.lat === location.lat && selectedWaste.lng === location.lng) {
      closePopout();
      return;
    }

    if (!selectedWaste && !selectedNeighbourhood && !selectedPainPoint) {
      if (mapRef.current) {
        setPreviousMapState({
          center: {
            lat: mapRef.current.getCenter().lat(),
            lng: mapRef.current.getCenter().lng()
          },
          zoom: mapRef.current.getZoom()
        });
      }
    }

    // Rest of your existing code
    setClickedPins(prev => [...prev, { lat: location.lat, lng: location.lng }]);
    const nearest = findNearestFacilities(location);
    setSelectedWaste(location);
    setNearestFacilities(nearest);
    setSelectedNeighbourhood(null);
    setSelectedPainPoint(null);

    if (mapRef.current && isLoaded) {
      const facilityPoints = Object.values(nearest)
        .filter(data => data !== null)
        .map(data => ({
          lat: data.facility.lat,
          lng: data.facility.lng
        }));
      calculateZoomForPoints(location, facilityPoints);
    }
  };


  const handlePolygonClick = (neighbourhood) => {
    setSelectedNeighbourhood(neighbourhood);
    setSelectedWaste(null);
    setNearestFacilities(null);
    setSelectedPainPoint(null);
    centerMapOnNeighbourhood(neighbourhood);
  };

  // Center map on a neighbourhood
  const centerMapOnNeighbourhood = useCallback((neighbourhood) => {
    if (!isLoaded || !window.google || !mapRef.current) return;

    const bounds = new window.google.maps.LatLngBounds();
    neighbourhood.coordinates.forEach(([lng, lat]) => {
      bounds.extend({ lat, lng });
    });

    mapRef.current.fitBounds(bounds);
  }, [isLoaded]);

  const getPainPointColor = (distance, count) => {
    return 'rgba(255, 152, 0, 0.8)'
    // const distanceScore = Math.min(1, distance / 3);
    // const countScore = Math.min(1, count / 30);
    // const severity = Math.max(distanceScore, countScore);

    // if (severity < 0.25) {
    //   return 'rgba(0, 159, 77, 0.75)';
    // } else if (severity < 0.5) {
    //   return 'rgba(141, 198, 63, 0.8)';
    // } else if (severity < 0.75) {
    //   return 'rgba(255, 152, 0, 0.8)';
    // } else {
    //   return 'rgba(103, 58, 183, 0.8)';
    // }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Nepoznato";
    const date = new Date(dateString);
    return date.toLocaleDateString('hr-HR');
  };

  const PainPointPopup = ({ painPoint }) => {
    // Determine trend direction and explanation
    const getTrendInfo = () => {
      // Single report case - no trend to show
      if (painPoint.report_count <= 1) {
        return {
          direction: 'single',
          text: 'Jedna prijava',
          dateDisplay: `Prijavljeno: ${formatDate(painPoint.oldest_report)}`
        };
      }

      // Multiple reports but same date
      if (painPoint.oldest_report === painPoint.newest_report) {
        return {
          direction: 'single-day',
          text: 'Više prijava u istom danu',
          dateDisplay: `Datum: ${formatDate(painPoint.oldest_report)}`
        };
      }

      // Calculate actual trend based on reporting_trend
      switch (painPoint.reporting_trend) {
        case 'Povećava se':
          return {
            direction: 'up',
            text: 'Broj prijava raste s vremenom',
            dateDisplay: `Od ${formatDate(painPoint.oldest_report)} do ${formatDate(painPoint.newest_report)}`
          };
        case 'Smanjuje se':
          return {
            direction: 'down',
            text: 'Broj prijava opada s vremenom',
            dateDisplay: `Od ${formatDate(painPoint.oldest_report)} do ${formatDate(painPoint.newest_report)}`
          };
        default:
          return {
            direction: 'stable',
            text: 'Jednolika učestalost prijava',
            dateDisplay: `Od ${formatDate(painPoint.oldest_report)} do ${formatDate(painPoint.newest_report)}`
          };
      }
    };

    const trendInfo = getTrendInfo();

    return (
      <div>
        {/* Key Metrics with improved explanations */}
        <div style={sectionStyle}>
          <div style={metricContainerStyle}>
            <div>
              <p style={metricLabelStyle}>Prosječna udaljenost do reciklažnog dvorišta</p>
              <p style={metricValueStyle}>{painPoint.avg_distance.toFixed(2)} km</p>
            </div>
            <div style={infoItemStyle}>
              <p style={metricLabelStyle}>Broj reciklažnih mjesta</p>
              <p style={metricValueStyle}>{painPoint.nearby_facility_count}</p>
            </div>

          </div>

          {/* Location Details */}
          <div style={locationContainerStyle}>
            <div style={infoItemStyle}>
              <p style={metricLabelStyle}>Koordinate</p>
              <p style={metricValueStyle}>{painPoint.lat.toFixed(4)}, {painPoint.lng.toFixed(4)}</p>
            </div>
            <div style={infoItemStyle}>
              <p style={metricLabelStyle}>Kvart</p>
              <p style={metricValueStyle}>{painPoint.district || "Nepoznato"}</p>
            </div>
          </div>
        </div>

        <div style={locationContainerStyle}>
          <div>
            <p style={metricLabelStyle}>Broj prijava</p>
            <p style={metricValueStyle}>{painPoint.report_count}</p>
          </div>
          <div style={infoItemStyle}>
            <p style={metricLabelStyle}>Radius</p>
            <p style={metricValueStyle}>{Math.max(painPoint.radius.toFixed(2), 0.350)} km</p>
          </div>
        </div>
        <br></br>
        {/* Trend Indicator with improved logic */}
        <div style={sectionStyle}>
          <div style={trendContainerStyle}>
            {trendInfo.direction === 'up' && (
              <svg width="24" height="24" viewBox="0 0 24 24" style={{ marginRight: '8px' }}>
                <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"
                  fill="#e74c3c" />
              </svg>
            )}

            {trendInfo.direction === 'down' && (
              <svg width="24" height="24" viewBox="0 0 24 24" style={{ marginRight: '8px' }}>
                <path d="M16 18l2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z"
                  fill="#27ae60" />
              </svg>
            )}

            {trendInfo.direction === 'stable' && (
              <svg width="24" height="24" viewBox="0 0 24 24" style={{ marginRight: '8px' }}>
                <path d="M22 12l-4-4v3H3v2h15v3z" fill={colors.textMedium} />
              </svg>
            )}

            {(trendInfo.direction === 'single' || trendInfo.direction === 'single-day') && (
              <svg width="16" height="16" viewBox="0 0 24 24" style={{ marginRight: '8px' }}>
                <circle cx="12" cy="12" r="8" fill={colors.textMedium} />
              </svg>
            )}
            <div>
              <p style={{ margin: 0, fontWeight: 'medium' }}>{trendInfo.text}</p>
              <p style={{ margin: '4px 0 0 0', fontSize: '12px', color: colors.textLight }}>
                {trendInfo.dateDisplay}
              </p>
            </div>
          </div>
        </div>

        {/* Infrastructure Section with better explanation */}


      </div>
    );
  };


  // Handle click on pain point
  const handlePainPointClick = (painPoint) => {
    setSelectedPainPoint(painPoint);
    setSelectedWaste(null);
    setNearestFacilities(null);
    setSelectedNeighbourhood(null);

    if (mapRef.current && isLoaded) {
      mapRef.current.setZoom(13);
      mapRef.current.panTo({ lat: painPoint.lat, lng: painPoint.lng });
    }
  };

  // Reset map view
  const resetMap = () => {
    resetSelections();
    if (mapRef.current && isLoaded) {
      mapRef.current.setZoom(defaultZoom);
      mapRef.current.panTo(defaultCenter);
    }
  };

  // Close the active popout
  const closePopout = () => {
    // Store the current selections in temporary variables
    const wasSelected = selectedWaste !== null;

    // Reset selections first
    resetSelections();

    if (wasSelected && mapRef.current && previousMapState) {
      setTimeout(() => {
        mapRef.current.setCenter(previousMapState.center);
        mapRef.current.setZoom(previousMapState.zoom);
      }, 10);
    }
  };

  // Map style options

  const mapOptions = {
    disableDefaultUI: true,
    gestureHandling: "auto",
    scrollwheel: true,
    disableDoubleClickZoom: true,
    styles: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]
  };

  const infoRowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2px',
  };

  const infoRowStyleBorder = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    borderBottom: '1px solid grey',
  };


  const containerTypesStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '16px',
    marginTop: '16px'
  };

  const containerTypeItemStyle = {
    border: '1px solid #eee',
    borderRadius: '4px',
    padding: '8px',
  };

  const containerTypeHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    marginBottom: '4px',
  };

  const noPointerStyle = {
    cursor: 'default'
  };

  const coverageBarContainerStyle = {
    position: 'relative',
    height: '20px',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    overflow: 'hidden',
  };

  const coverageBarStyle = {
    height: '100%',
    transition: 'width 0.3s ease',
  };

  const distanceInfoStyle = {
    marginBottom: '16px',
  };

  const insightBoxStyle = {
    backgroundColor: '#f8f9fa',
    padding: '10px',
    borderRadius: '4px',
    borderLeft: '4px solid #007bff',
  };

  const getCoverageColor = (percentage) => {
    if (percentage >= 80) return 'rgb(0, 159, 77)';
    if (percentage >= 50) return 'rgb(255, 186, 8)';
    return 'rgb(220, 53, 69)';
  };

  // Polygon style options
  const polygonOptions = {
    fillColor: "#00FF00",
    fillOpacity: 0.04,
    strokeColor: "#000000",
    strokeOpacity: 0.65,
    strokeWeight: 1,
    strokeDasharray: [8, 8],
    clickable: true,
    editable: false,
    draggable: false,
  };

  // Handle map load
  const onMapLoad = (map) => {
    mapRef.current = map;

    map.addListener('dragstart', () => setIsUserInteracting(true));
    map.addListener('zoom_changed', () => setIsUserInteracting(true));

    map.addListener('idle', () => {
      if (isUserInteracting && !selectedWaste) {
        setPreviousMapState({
          center: {
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng()
          },
          zoom: map.getZoom()
        });
        setIsUserInteracting(false);
      }
    });
  };

  if (loadError) {
    return <div>Error loading maps: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  const clusterOptions = {
    maxZoom: 17,
    gridSize: 15,
    zoomOnClick: false,
    styles: [{
      height: 40,
      width: 40,
      url: 'data:image/svg+xml;base64,' + btoa(`
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
          <circle cx="20" cy="20" r="20" fill="#009F4D" />
        </svg>
      `),
      textColor: 'white',
      textSize: 14,
      anchor: [20, 20]
    }],
  };

  return (
    <div style={containerStyle}>
      {/* View Selector */}
      <div style={viewSelectorStyle}>
        <button
          style={{
            ...viewButtonStyle,
            backgroundColor: activeView === 'reported' ? '#009F4D' : '#f8f8f8'
          }}
          onClick={() => setActiveView('reported')}
        >
          Prijavljeni incidenti
        </button>
        <button
          style={{
            ...viewButtonStyle,
            backgroundColor: activeView === 'neighbourhoods' ? '#009F4D' : '#f8f8f8'
          }}
          onClick={() => setActiveView('neighbourhoods')}
        >
          Gradske četvrti
        </button>
        <button
          style={{
            ...viewButtonStyle,
            backgroundColor: activeView === 'painpoints' ? '#009F4D' : '#f8f8f8'
          }}
          onClick={() => setActiveView('painpoints')}
        >
          Kritične zone
        </button>
      </div>

      {/* Popout for selected item */}
      {(selectedWaste || selectedNeighbourhood || selectedPainPoint) && (
        <div style={selectedPainPoint ? popoutCriticalStyle : popoutStyle}>
          <div style={popoutHeaderStyle}>
            <h3 style={popoutTitleStyle}>
              {selectedWaste && "Prijavljeni incident"}
              {selectedNeighbourhood && `Gradska četvrt ${selectedNeighbourhood.name}`}
              {selectedPainPoint && "Kritična zona"}
            </h3>
            <button style={closeButtonStyle} onClick={selectedWaste ? closePopout : resetMap}>×</button>
          </div>
          <div style={popoutContentStyle}>
            {/* Reported Waste Popout Content */}
            {selectedWaste && (
              <div>
                <div style={locationContainerStyle}>
                  <div style={infoItemStyle}>
                    <p style={metricLabelStyle}>Koordinate</p>
                    <p style={metricValueStyle}>({selectedWaste.lat.toFixed(4)}, {selectedWaste.lng.toFixed(4)})</p>
                  </div>
                  <div style={infoItemStyle}>
                    <p style={metricLabelStyle}>Datum prijave</p>
                    <p style={metricValueStyle}>{formatDate(selectedWaste.reportedDate)}</p>
                  </div>
                </div>
                <br></br>

                {nearestFacilities && (
                  <div>
                    <p style={infoLabelStyle}>Najbliže lokacije za zbrinjavanje otpada:</p>
                    <div style={facilitiesListStyle}>
                      {Object.entries(nearestFacilities).map(([type, data]) => {
                        if (!data) return null;
                        return (
                          <div key={type} style={facilityItemStyle}>
                            <p style={facilityTypeStyle}>{type}</p>
                            <p style={facilityDistanceStyle}>{data.distance.toFixed(2)} km</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Neighbourhood Popout Content */}
            {selectedNeighbourhood && (
              <div className="neighbourhood-info-popout">
                <div style={popoutInfoItemStyle}>
                  <p style={popoutLabelStyle}>Stanovništvo</p>
                  <p style={popoutValueStyle}>{selectedNeighbourhood.population.toLocaleString('fr-FR')}</p>
                </div>

                {selectedNeighbourhood.area_square_meters && (
                  <div style={popoutInfoItemStyle}>
                    <p style={popoutLabelStyle}>Površina (km²)</p>
                    <p style={popoutValueStyle}>{(selectedNeighbourhood.area_square_meters / 1000000).toFixed(2)} km²</p>
                  </div>
                )}

                {selectedNeighbourhood.total_containers && (
                  <>
                    <div style={popoutInfoItemStyle}>
                      <p style={popoutLabelStyle}>Ukupno spremnika</p>
                      <p style={popoutValueStyle}>{selectedNeighbourhood.total_containers}</p>
                    </div>
                    <div style={popoutInfoItemStyle}>
                      <p style={popoutLabelStyle}>Spremnika na 1000 stanovnika</p>
                      <p style={popoutValueStyle}>{(selectedNeighbourhood.containers_per_capita * 1000).toFixed(2)}</p>
                    </div>
                    <div style={popoutInfoItemStyle}>
                      <p style={popoutLabelStyle}>Spremnika po km²</p>
                      <p style={popoutValueStyle}>{selectedNeighbourhood.containers_per_square_km.toFixed(2)}</p>
                    </div>
                  </>)}

                {selectedNeighbourhood.total_containers ? (
                  <>
                    <div style={containerTypesStyle}>
                      {CATEGORIES.map(category => {
                        const categoryKey = category.toLowerCase().replace(/\s+/g, '_');
                        const count = selectedNeighbourhood[`${categoryKey}_containers`] || 0;
                        const coveragePct = selectedNeighbourhood[`${categoryKey}_coverage_percentage`] || 0;

                        return count > 0 ? (
                          <div key={category} style={containerTypeItemStyle}>
                            <div style={containerTypeHeaderStyle}>
                              <span>{category}</span>
                              <span>{count}</span>
                            </div>
                            <div style={coverageBarContainerStyle}>
                              <div
                                style={{
                                  ...coverageBarStyle,
                                  width: `${Math.min(100, coveragePct)}%`,
                                  backgroundColor: getCoverageColor(coveragePct)
                                }}
                              />
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div style={distanceInfoStyle}>
                      <p style={{ marginBottom: '10px' }}>Prosječne udaljenosti kontejnera</p>
                      {CATEGORIES.map(category => {
                        const categoryKey = category.toLowerCase().replace(/\s+/g, '_');
                        const avgDistance = selectedNeighbourhood[`avg_distance_between_${categoryKey}`];

                        return avgDistance ? (
                          <div key={category} style={infoRowStyleBorder}>
                            <p>{category}:</p><span>{(avgDistance).toFixed(0)} m</span>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </>
                ) : (
                  <p>Za ovo naselje nisu dostupni podaci o gospodarenju otpadom.</p>
                )}
              </div>
            )}

            {/* Pain Point Popout Content */}
            {selectedPainPoint && (
              <div className="info-panel">
                <PainPointPopup painPoint={selectedPainPoint} />
              </div>
            )}
          </div>
        </div>
      )}

      {/* Google Map */}

      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={mapCenter}
        zoom={defaultZoom}
        options={mapOptions}
        onLoad={onMapLoad}
      >
        {/* Neighbourhoods */}
        {activeView === 'neighbourhoods' && neighbourhoods.map((neighbourhood) => (
          <Polygon
            key={neighbourhood.id}
            paths={neighbourhood.coordinates.map(([lng, lat]) => ({ lat, lng }))}
            options={{
              ...polygonOptions,
              fillColor: selectedNeighbourhood && selectedNeighbourhood.id === neighbourhood.id
                ? "rgb(255, 0, 64)"
                : "#00FF00",
              fillOpacity: selectedNeighbourhood && selectedNeighbourhood.id === neighbourhood.id
                ? 0.2
                : 0.04

            }}
            onClick={() => handlePolygonClick(neighbourhood)}
          />
        ))}

        {/* Management Facilities */}

        <MarkerClusterer
          {...clusterOptions}
          clusterClass="custom-cluster"
        >
          {(clusterer) => (
            <>
              {filteredLocations.map((location, index) => (
                <Marker
                  key={`facility-${index}`}
                  position={{ lat: location.lat, lng: location.lng }}
                  clusterer={clusterer}
                  icon={getFacilityIcon(location.type)}
                  clickable={false}
                />
              ))}
            </>
          )}
        </MarkerClusterer>


        {/* Reported Locations */}
        {activeView === 'reported' && reportedLocations.map((location, index) => (
          (!selectedWaste || (selectedWaste && selectedWaste.lat === location.lat && selectedWaste.lng === location.lng)) && (
            <Marker
              key={`reported-${index}`}
              position={{ lat: location.lat, lng: location.lng }}
              icon={getReportedIcon(location, index)}
              onClick={() => handleReportedLocationClick(location)}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
              zIndex={selectedWaste && selectedWaste.lat === location.lat && selectedWaste.lng === location.lng ? 20 : 2}
            />
          )
        ))}

        {/* Pain Points */}
        {activeView === 'painpoints' && painPoints.map((point, index) => (
          <Circle
            key={`pain-${index}`}
            center={{ lat: point.lat, lng: point.lng }}
            radius={Math.max(point.radius * 1000, 350)} // Ensure minimum radius
            options={{
              fillColor: getPainPointColor(point.avg_distance, point.report_count),
              fillOpacity: selectedPainPoint && selectedPainPoint.lat === point.lat ? 0.8 : 0.6,
              strokeColor: colors.secondary,
              strokeWeight: 2,
            }}
            onClick={() => handlePainPointClick(point)}
          />
        ))}

        {/* Connection lines between waste and facilities */}
        {selectedWaste && nearestFacilities && Object.entries(nearestFacilities).map(([type, data]) => {
          if (!data) return null;
          const { facility } = data;

          return (
            <Polyline
              key={`line-${type}`}
              path={[
                { lat: selectedWaste.lat, lng: selectedWaste.lng },
                { lat: facility.lat, lng: facility.lng }
              ]}
              options={{
                strokeColor: getFacilityColor(type),
                strokeWeight: 2,
                strokeOpacity: 0.8
              }}
            />
          );
        })}
      </GoogleMap>
    </div>
  );
};

// Styles
const containerStyle = {
  position: 'relative',
  width: '100%',
  height: 'calc(100vh - 131px)',
  border: '1px solid #ccc',
  overflow: 'hidden'
};

const viewSelectorStyle = {
  position: 'absolute',
  top: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 10,
  backgroundColor: '#fff',
  padding: '10px',
  borderRadius: '5px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
  display: 'flex',
  gap: '10px',
};

const viewButtonStyle = {
  padding: '8px 16px',
  borderRadius: '4px',
  border: '1px solid #ddd',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  fontFamily: '"medium", sans-serif'
};

const popoutStyle = {
  position: 'absolute',
  top: '80px',
  right: '20px',
  width: '350px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
  zIndex: 10,
  overflow: 'hidden'
};


const popoutCriticalStyle = {
  position: 'absolute',
  top: '80px',
  right: '20px',
  width: '480px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
  zIndex: 10,
  overflow: 'hidden'
};

const popoutHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 15px',
  backgroundColor: '#009F4D',
  color: 'white',
  fontFamily: '"extra-bold", sans-serif'
};

const popoutTitleStyle = {
  margin: 0,
  fontWeight: 'bold',
  fontSize: '1rem'
};

const closeButtonStyle = {
  background: 'none',
  border: 'none',
  color: 'white',
  fontSize: '24px',
  cursor: 'pointer',
  padding: '0 5px'
};

const popoutContentStyle = {
  padding: '15px',
  maxHeight: '650px',
  overflowY: 'auto',
  fontFamily: '"medium", sans-serif'
};

const infoLabelStyle = {
  marginBottom: '3px',
  marginTop: '12px',
  color: '#333'
};

const infoLabelStyleN = {
  color: '#333'
};

const infoLabelStyleM = {
  marginTop: '15px',
  color: '#333'
};

const facilitiesListStyle = {
  marginTop: '8px',
  marginBottom: '12px'
};

const facilityItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '5px 0',
  borderBottom: '1px solid #eee'
};

const facilityTypeStyle = {
  fontWeight: 'bold',
  margin: 0
};

const facilityDistanceStyle = {
  margin: 0,
  color: '#666'
};


const colors = {
  primary: 'rgb(0, 159, 77)',
  primaryLight: 'rgba(0, 159, 77, 0.2)',
  primaryMedium: 'rgba(0, 159, 77, 0.5)',
  primaryDark: 'rgba(0, 159, 77, 0.8)',
  secondary: 'rgb(0, 110, 53)',
  highlight: 'rgb(255, 193, 7)',
  textDark: '#333333',
  textMedium: '#666666',
  textLight: '#999999',
};


const sectionStyle = {
  marginBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
};

const headerStyle = {
  color: colors.textDark,
  fontSize: '18px',
  margin: '0 0 16px 0',
  paddingBottom: '8px',
  borderBottom: `1px solid ${colors.primaryLight}`,
};

const metricContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: colors.primaryLight,
  padding: '12px',
  borderRadius: '8px',
  marginBottom: '12px',
};

const metricLabelStyle = {
  fontSize: '14px',
  color: colors.textMedium,
  margin: '0',
};

const metricValueStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: colors.textDark,
  margin: '0',
};

const locationContainerStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '12px',
};

const infoItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
};

const trendContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 12px',
  borderRadius: '6px',
  backgroundColor: '#f5f5f5',
};

const trendIconUpStyle = {
  width: 0,
  height: 0,
  borderLeft: '8px solid transparent',
  borderRight: '8px solid transparent',
  borderBottom: '12px solid #e74c3c',
  marginRight: '6px',
};

const trendIconDownStyle = {
  width: 0,
  height: 0,
  borderLeft: '8px solid transparent',
  borderRight: '8px solid transparent',
  borderTop: '12px solid #27ae60',
  marginRight: '6px',
};

const trendIconStableStyle = {
  width: '12px',
  height: '2px',
  backgroundColor: colors.textMedium,
  marginRight: '6px',
};

const popoutInfoItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 12px',
  marginBottom: '5px',
  borderRadius: '6px',
  backgroundColor: '#f5f5f5',
};

const popoutLabelStyle = {
  fontSize: '14px',
  color: colors.textMedium,
  margin: '0',
};

const popoutValueStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: colors.textDark,
  margin: '0',
};

export default WasteManagementMap;